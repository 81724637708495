<template>
  <div>
    <h1 class="mb-4">{{ product?.serial }} {{ product?.name }}</h1>
    <div v-for="item in data" :key="item.id">
      <div
        class="nature-white-box mb-2"
        v-if="item?.productVariantSaleForecast?.length ?? 0 > 0"
      >
        <h2 class="bold-16 my-2">
          {{ item.sku }}
          <template
            v-for="characteristics in item.characteristics"
            :key="characteristics"
          >
            - {{ characteristics }}
          </template>
        </h2>
        <div class="row">
          <div class="col-1">
            <div class="mb-1 row" v-for="year in years" :key="year">
              <div class="col-12 regular-16 mb-1">Date</div>
              <div class="col-12 semibold-16 mb-1">Sales/forecast</div>
              <div class="col-12 semibold-16 mb-1">Scheduled in</div>
              <div class="col-12 bold-16 mb-1">Ending stock</div>
            </div>
          </div>
          <div class="col-11 row mb-3">
            <div
              class="col-1 mb-1"
              v-for="forecast in item.productVariantSaleForecast"
              :key="forecast.id"
            >
              <div class="regular-16 mb-1">
                {{ formatDate(forecast.date) }}
              </div>
              <div class="semibold-16 text-green mb-1" v-if="forecast.sales">
                {{ forecast.sales }}
              </div>
              <div
                class="semibold-16 text-gray-dark mb-1"
                v-else-if="forecast.forecast < 0.02"
              >
                0
              </div>
              <div class="semibold-16 mb-1" v-else>
                {{ forecast.forecast }}
              </div>
              <div class="semibold-16 mb-1">
                {{ forecast.incoming ?? "-" }}
              </div>
              <div
                class="bold-16 mb-1"
                :class="{ 'text-danger': (forecast.stock ?? 0) < 0 }"
              >
                {{ forecast.stock ?? "-" }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "@/modules/http";
import { useStore } from "vuex";
import date from "../../modules/date";

export default {
  name: "ProductForecast",
  components: {},
  data() {
    return {
      store: useStore(),
      product: {},
      data: null,
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    variantId() {
      return this.$route.params.variant_id;
    },
    date() {
      return this.store.state.topBar.date;
    },
    years() {
      return Math.min(
        new Date().getUTCFullYear() - this.date[0].getUTCFullYear() + 2,
        6
      );
    },
    q() {
      if (
        this.store.state.topBar.q === null ||
        this.store.state.topBar.q === undefined
      ) {
        return this.store.state.topBar.q;
      }
      return this.store.state.topBar.q
        .replace(this.product?.serial, "")
        .replace(this.product?.name, "")
        .trim();
    },
  },
  watch: {
    date: {
      deep: true,
      handler() {
        this.loadData();
      },
    },
    q() {
      this.loadData();
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    formatDate(val) {
      return date.format(val, false, false);
    },
    loadData() {
      http
        .fetch(
          `/products/${this.id}/forecast?1${this.q ? "&q=" + this.q : ""}${
            this.variantId ? "&variant=" + this.variantId : ""
          }${
            this.date[0]
              ? "&from=" + this.date[0].toISOString().split("T")[0]
              : ""
          }`
        )
        .then((data) => {
          this.product = data.product;
          this.data = data.rows;
        });
    },
  },
};
</script>
